import { CreateMultipartResponse } from '@air/api/types';
import { isAirror, MAX_ANCESTOR_COUNT_EXCEEDED } from '@air/errors';
import {
  LargeUpload,
  setLargeUploadUrlInfoAction,
  setUploadFailedAction,
  setUploadPreparingAction,
  Upload,
  UploadStatus,
} from '@air/redux-uploader';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { isAbortError } from '../utilities';

export const useCreateLargeUpload = () => {
  const dispatch = useDispatch();

  const createLargeUpload = useCallback(
    async ({
      getLargeUploadUrlInfo,
      onUploadStarted,
      upload,
      onError,
    }: {
      upload: LargeUpload;
      getLargeUploadUrlInfo: () => Promise<CreateMultipartResponse[0] | undefined>;
      onUploadStarted: (params: { uploadId: Upload['id'] }) => void;
      onError: (params: { upload: Upload; error: unknown }) => void;
    }) => {
      if (upload.status === UploadStatus.queued) {
        try {
          dispatch(setUploadPreparingAction({ uploadId: upload.id }));

          const info = upload.s3Info.uploadUrlInfo ?? (await getLargeUploadUrlInfo());

          if (info) {
            dispatch(setLargeUploadUrlInfoAction({ uploadId: upload.id, uploadUrlInfo: info }));
            onUploadStarted({ uploadId: upload.id });
          }
        } catch (error) {
          if (!isAbortError(error)) {
            dispatch(
              setUploadFailedAction({
                uploadId: upload.id,
                errorMessage:
                  isAirror(error) && error.type === MAX_ANCESTOR_COUNT_EXCEEDED.type
                    ? 'Max ancestor count exceeded'
                    : 'Failed',
              }),
            );
          }
          onError({ upload, error });
        }
      }
    },
    [dispatch],
  );

  return {
    createLargeUpload,
  };
};
