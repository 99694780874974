import { Board } from '@air/api/types';
import { createAction } from '@reduxjs/toolkit';

export const addPathToBoardIdAction = createAction<{
  path: string;
  boardId: Board['id'];
  pathRootId: string;
}>('ADD_PATH_TO_BOARDID');

export const setCreatingUploadBoardsAction = createAction<{
  paths: string[];
  pathRootId: string;
}>('SET_CREATING_BOARDS_FOR_UPLOAD');

export const setCreatingUploadBoardsFailedAction = createAction<{
  paths: string[];
  pathRootId: string;
  error: unknown;
}>('SET_CREATING_BOARDS_FOR_UPLOAD_FAILED');
