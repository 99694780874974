import type {
  AssetRestorationTask,
  BoardDeletionTask,
  BoardRestorationTask,
  CTWTask,
  DuplicateBoardTask,
  LibraryDeletionTask,
  LibraryRestorationTask,
  Task,
  ZippingTask,
} from '~/store/tasks/types';

export const isTaskZippingTask = (task: Pick<Task, 'type'>): task is ZippingTask => task.type === 'ZippingForDownload';

export const getZippingTasksFromTasks = (tasks: Task[]) =>
  tasks.filter((task): task is ZippingTask => isTaskZippingTask(task));

export const isCopyToWorkspaceTask = (task: Pick<Task, 'type'>): task is CTWTask => task.type === 'CopyToWorkspace';

export const getCTWTasksFromTasks = (tasks: Task[]) =>
  tasks.filter((task): task is CTWTask => isCopyToWorkspaceTask(task));

export const isDuplicateBoardTask = (task: Pick<Task, 'type'>): task is DuplicateBoardTask =>
  task.type === 'DuplicateBoard';

export const isBoardDeletionTask = (task: Pick<Task, 'type'>): task is BoardDeletionTask =>
  task.type === 'BoardDeletion';

export const isLibraryDeletionTask = (task: Pick<Task, 'type'>): task is LibraryDeletionTask =>
  task.type === 'LibraryDeletion';

export const isBoardRestorationTask = (task: Pick<Task, 'type'>): task is BoardRestorationTask =>
  task.type === 'BoardRestoration';

export const isLibraryRestorationTask = (task: Pick<Task, 'type'>): task is LibraryRestorationTask =>
  task.type === 'LibraryRestoration';

export const isAssetRestorationTask = (task: Pick<Task, 'type'>): task is AssetRestorationTask =>
  task.type === 'AssetRestoration';

export const getDuplicateBoardTasksFromTasks = (tasks: Task[]) => tasks.filter(isDuplicateBoardTask);

export const getBoardDeletionTasksFromTasks = (tasks: Task[]) => tasks.filter(isBoardDeletionTask);

export const getLibraryDeletionTasksFromTasks = (tasks: Task[]) => tasks.filter(isLibraryDeletionTask);

export const getBoardRestorationTasksFromTasks = (tasks: Task[]) => tasks.filter(isBoardRestorationTask);

export const getLibraryRestorationTasksFromTasks = (tasks: Task[]) => tasks.filter(isLibraryRestorationTask);

export const getAssetRestorationTasksFromTasks = (tasks: Task[]) => tasks.filter(isAssetRestorationTask);
