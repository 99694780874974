const SIZES = [`B`, `KB`, `MB`, `GB`, `TB`, `PB`, `EB`, `ZB`, `YB`] as const;

interface FormatBytesOptions {
  numberOfDecimals: number;
  overrideUnit: (typeof SIZES)[number];
}

export const BYTES_TERABYTE = 1e12;

/**
 * @description Formats bytes from a number to a formatted string showing the largest byte measurement unit
 * @example formatBytes(3200000000000) ===> "3.2 TB"
 * @param bytes - The number of bytes that are being converted
 * @param options - Options to pass to this function.
 * @returns A formatted number that ends in bytes, KB, MB, GB, TB, PB, EB, ZB, or YB
 */
export const formatBytes = (bytes: number, options: Partial<FormatBytesOptions> = {}): string => {
  const { numberOfDecimals = 0, overrideUnit = undefined } = options;

  if (!overrideUnit) {
    if (!bytes || bytes <= 0) return `0 B`;
    if (bytes === 1) return `1 B`;
  }

  const base = 1000;

  const exponent = overrideUnit ? SIZES.indexOf(overrideUnit) : ~~(Math.log(bytes) / Math.log(base));
  const unitValue = bytes / base ** exponent;
  const rawValue = unitValue <= 1e-9 ? 0 : unitValue;

  let [whole, partial = ``] = rawValue.toString().split(`.`);

  if (numberOfDecimals > 0) {
    const count = numberOfDecimals - partial.length;
    if (count > 0) partial += `0`.repeat(count);
    whole += `.${partial.slice(0, numberOfDecimals)}`;
  }

  const abbreviationSuffix = SIZES[exponent];

  if (rawValue >= base && typeof Number(whole) === `number`) {
    return `${Number(whole).toLocaleString()} ${abbreviationSuffix}`;
  }

  return `${whole} ${abbreviationSuffix}`;
};

/**
 * @description Determines the appropriate amount of decimal places based on a number of bytes
 * @example formatDecimals(3200000000000) ===> 2
 * @param bytes The number of bytes that are being checked
 * @returns The number of appropriate decimal places
 */
export const formatDecimals = (bytes = 0): number => (bytes >= 1e9 ? 2 : 0);
