import { createReducer } from '@reduxjs/toolkit';

import { resetPermissionsAction, setPermissionsAction } from './actions';
import { PermissionsState } from './types';

const initialState: PermissionsState = {
  assetIds: {},
  boardIds: {},
  clipIds: {},
  discussionIds: {},
  formIds: {},
  libraryIds: {},
  savedFilterIds: {},
  shortIds: {},
  workspaceId: {},
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetPermissionsAction, () => initialState)
    .addCase(setPermissionsAction, (state, { payload }) => {
      (Object.keys(payload) as (keyof typeof payload)[]).forEach((scope) => {
        state[scope] = {
          ...state[scope],
          ...payload[scope],
        };
      });
    });
});
