import { type Clip, ClipType } from '@air/api/types';

export type Nullify<T, K extends keyof T> = Omit<T, K> & { [P in K]: T[P] | null };

export type WebClipType = 'pdf' | 'document' | 'photo' | 'video' | 'file';

export const getAssetPreviewType = ({
  type,
  ext,
}: Partial<Nullify<Pick<Clip, 'type' | 'ext'>, 'ext' | 'type'>>): WebClipType | undefined => {
  if (ext?.toLowerCase() === 'pdf') {
    return 'pdf';
  }

  if (type === ClipType.nonMedia) {
    return 'file';
  }

  return (['document', 'photo', 'video', 'file'] as WebClipType[]).find((t) => t === type);
};
