import { GetBoardStatsResponse } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { useCallback } from 'react';

import { GalleryItemType } from '~/components/Gallery/types';
import { getBaseBoardStatsKey } from '~/constants/react-query-keys';

const initialState: GetBoardStatsResponse = {
  assetCount: 0,
  fileCount: 0,
  sizeBytes: 0,
};

export const useUpdateBoardStats = () => {
  const queryClient = useQueryClient();
  const updateBoardStats = useCallback(
    (
      boardId: Board['id'],
      updateFn?: (data?: GetBoardStatsResponse) => GetBoardStatsResponse | undefined,
      revalidate = true,
    ) => {
      queryClient.setQueryData<GetBoardStatsResponse | undefined>(getBaseBoardStatsKey(boardId), updateFn);
      if (!!revalidate) {
        queryClient.invalidateQueries({ queryKey: getBaseBoardStatsKey(boardId) });
      }
    },
    [queryClient],
  );

  return {
    updateBoardStats,
  };
};

export const useUpdateItemsCount = () => {
  const { updateBoardStats } = useUpdateBoardStats();

  const addItems = useCallback(
    (boardId: Board['id'], assets: Clip[], type: GalleryItemType.asset | GalleryItemType.file, revalidate = true) => {
      updateBoardStats(
        boardId,
        (data) => {
          const count = assets.length;
          const size = assets.reduce((acc, curr) => {
            acc += curr.size;
            return acc;
          }, 0);
          return data
            ? produce(data, (draft) => {
                if (type === GalleryItemType.file) {
                  draft.fileCount += count;
                } else {
                  draft.assetCount += count;
                }
                draft.sizeBytes += size;
              })
            : {
                ...initialState,
                sizeBytes: size,
                ...(type === GalleryItemType.file ? { fileCount: count } : { assetCount: count }),
              };
        },
        revalidate,
      );
    },
    [updateBoardStats],
  );

  const removeItems = useCallback(
    (boardId: Board['id'], count: number, type: GalleryItemType.asset | GalleryItemType.file) => {
      updateBoardStats(
        boardId,
        (data) =>
          data &&
          produce(data, (draft) => {
            if (type === GalleryItemType.file) {
              draft.fileCount = Math.max(0, draft.fileCount - count);
            } else {
              draft.assetCount = Math.max(0, draft.assetCount - count);
            }
          }),
      );
    },
    [updateBoardStats],
  );

  return {
    addItems,
    removeItems,
  };
};
