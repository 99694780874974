import { createReducer } from '@reduxjs/toolkit';

import {
  setApplyAllAction,
  setContentCollectionUploadedClipsAction,
  setCustomFieldDateValueAction,
  setCustomFieldPlainTextValueAction,
  setCustomFieldsAction,
  setCustomFieldValueAction,
  setCustomFieldValuesAction,
  setFormAction,
  setShortIdAction,
  setTouchedAction,
  setViewAction,
} from './actions';
import { type ContentCollectionState, ContentCollectionView } from './types';

export const initialState: ContentCollectionState = {
  applyAll: false,
  customFields: [],
  form: undefined,
  shortId: undefined,
  touched: false,
  uploadedClips: [],
  view: ContentCollectionView.Onboarding,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setApplyAllAction, (state, { payload: { applyAll } }) => {
    state.applyAll = applyAll;
  });

  builder.addCase(setCustomFieldsAction, (state, { payload: { customFields } }) => {
    state.customFields = customFields;
  });

  builder.addCase(setCustomFieldPlainTextValueAction, (state, { payload: { customField } }) => {
    const customFieldIndex = state.customFields.findIndex(({ id }) => id === customField.id);

    if (customFieldIndex !== -1) {
      state.customFields[customFieldIndex] = customField;
    } else {
      state.customFields.push(customField);
    }
  });

  builder.addCase(setCustomFieldDateValueAction, (state, { payload: { customField } }) => {
    const customFieldIndex = state.customFields.findIndex(({ id }) => id === customField.id);

    if (customFieldIndex !== -1) {
      state.customFields[customFieldIndex] = customField;
    } else {
      state.customFields.push(customField);
    }
  });

  builder.addCase(setCustomFieldValuesAction, (state, { payload: { customField } }) => {
    const customFieldIndex = state.customFields.findIndex(({ id }) => id === customField.id);

    if (customFieldIndex !== -1) {
      state.customFields[customFieldIndex] = customField;
    } else {
      state.customFields.push(customField);
    }
  });

  builder.addCase(setCustomFieldValueAction, (state, { payload: { customField } }) => {
    const customFieldIndex = state.customFields.findIndex(({ id }) => id === customField.id);

    if (customFieldIndex !== -1) {
      state.customFields[customFieldIndex] = customField;
    } else {
      state.customFields.push(customField);
    }
  });

  builder.addCase(setTouchedAction, (state, { payload: { touched } }) => {
    state.touched = touched;
  });

  builder.addCase(setViewAction, (state, { payload: { view } }) => {
    state.view = view;
  });

  builder.addCase(setFormAction, (state, { payload: { form } }) => {
    state.form = form;
  });

  builder.addCase(setShortIdAction, (state, { payload: { shortId } }) => {
    state.shortId = shortId;
  });

  builder.addCase(setContentCollectionUploadedClipsAction, (state, { payload: { uploadedClips } }) => {
    state.uploadedClips = uploadedClips;
  });
});
