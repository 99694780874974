import { setUploadAbortedAction } from '@air/redux-uploader';
import { createReducer } from '@reduxjs/toolkit';

import {
  addUploadingClipToBoardCollectionAction,
  clearBoardCollectionClipsAction,
  deleteBoardCollectionClipsAction,
  setAttemptedSubmissionAction,
  setBoardCollectionViewAction,
  setFormAction,
  setHasSubmittedAction,
  setSessionIdAction,
  updateBoardCollectionClipCustomFieldAction,
  updateBoardCollectionClipsAction,
  updateBoardCollectionCustomFieldsInAllClipsAction,
} from './actions';
import { BoardCollectionState } from './types';

export const initialState: BoardCollectionState = {
  hasSubmitted: false,
  attemptedSubmission: false,
  clips: {},
  shortId: undefined,
  form: undefined,
  sessionId: undefined,
  view: 'onboarding',
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addUploadingClipToBoardCollectionAction, (state, { payload: { clip, uploadId } }) => {
      state.clips = {
        ...state.clips,
        [clip.id]: {
          ...clip,
          uploadId,
          // after a clip is uploaded and set inside this reducer, we want to set it's title field equal to importedName
          // this is because board collection always requires a title to be present
          title: clip.importedName,
        },
      };
    })
    .addCase(setUploadAbortedAction, (state, { payload: { uploadId } }) => {
      state.clips = Object.fromEntries(Object.entries(state.clips).filter(([_, clip]) => clip.uploadId !== uploadId));
    })
    .addCase(deleteBoardCollectionClipsAction, (state, { payload: { clipIds } }) => {
      clipIds.forEach((id) => {
        const clip = state.clips[id];
        if (clip) {
          delete state.clips[id];
        }
      });
    })
    .addCase(updateBoardCollectionClipsAction, (state, { payload: { clips } }) => {
      clips.forEach((clip) => {
        state.clips[clip.id] = {
          ...(state.clips[clip.id] || {}),
          ...clip,
        };
      });
    })
    .addCase(updateBoardCollectionClipCustomFieldAction, (state, { payload: { clipId, customField } }) => {
      state.clips[clipId] = {
        ...state.clips[clipId],
        customFields: [
          ...(state.clips[clipId]?.customFields?.filter((cf) => cf.id !== customField.id) || []),
          {
            ...customField,
            assetId: state.clips[clipId]?.assetId,
          },
        ],
      };
    })
    .addCase(updateBoardCollectionCustomFieldsInAllClipsAction, (state, { payload: { customField } }) => {
      Object.values(state.clips).forEach((clip) => {
        if (clip.customFields?.length) {
          if (clip.customFields.some((cf) => cf.id === customField.id)) {
            clip.customFields = clip.customFields.map((cf) =>
              cf.id === customField.id
                ? {
                    ...cf,
                    ...customField,
                  }
                : cf,
            );
          } else {
            clip.customFields.push({
              ...customField,
              assetId: clip.assetId,
            });
          }
        } else {
          clip.customFields = [
            {
              ...customField,
              assetId: clip.assetId,
            },
          ];
        }
      });
    })
    .addCase(clearBoardCollectionClipsAction, (state) => {
      state.clips = {};
    })
    .addCase(setHasSubmittedAction, (state, { payload: { hasSubmitted } }) => {
      state.hasSubmitted = hasSubmitted;
    })
    .addCase(setAttemptedSubmissionAction, (state, { payload: { attemptedSubmission } }) => {
      state.attemptedSubmission = attemptedSubmission;
    })
    .addCase(setSessionIdAction, (state, { payload: { sessionId } }) => {
      state.sessionId = sessionId;
    })
    .addCase(setFormAction, (state, { payload: { form } }) => {
      state.form = form;
    })
    .addCase(setBoardCollectionViewAction, (state, { payload: { view } }) => {
      state.view = view;
    });
});
