import {
  CardSize,
  ConfigurableView,
  CustomFieldValue,
  SortDirection,
  ThumbnailStyle,
  ViewField,
  ViewTypeName,
  VisibleColumn,
  VisibleField,
} from '@air/api/types';
import { createAction } from '@reduxjs/toolkit';

/** Sets the board.views property, otherwise sets defaults */
export const setSavedConfigurableViewsAction = createAction<{
  savedConfigurableViews: ConfigurableView[];
}>('SET_SAVED_CONFIGURABLE_VIEWS');

/** Triggered after saving a view to update an individual saved view in store */
export const setSavedConfigurableViewAction = createAction<{ configurableView: ConfigurableView }>(
  'SET_SAVED_CONFIGURABLE_VIEW',
);

/** Used by hook, stores the response fetching the configurable view fields available based on view type */
export const setConfigurableViewFieldsAction = createAction<{ configurableViewFields: ViewField[] }>(
  'SET_CONFIGURABLE_VIEW_FIELDS',
);

/** Transitions the view to the corresponding saved view type */
export const setCurrentViewTypeAction = createAction<{ viewTypeName: ViewTypeName }>('SET_CURRENT_VIEW_TYPE');

/** Toggles the visibility of a field */
export const setFieldsVisibilityAction = createAction<{
  fields: VisibleField[];
  nextIsFieldVisible: boolean;
  defaultToSavedVisibility?: boolean;
}>('SET_FIELDS_VISIBILITY');

/** Resets to initial state */
export const resetConfigViewsAction = createAction('RESET_CONFIG_VIEWS');

/** Chooses this sort field with direction override option, otherwise chooses default direction for
 * Sorting option.
 */
export const setSortingFieldAction = createAction<{ sortingFieldName: string; direction?: SortDirection }>(
  'SET_SORTING_FIELD',
);

export const setThumbnailPreferenceAction = createAction<{ thumbnailPreference: ThumbnailStyle }>(
  'SET_THUMBNAIL_PREFERENCE',
);

export const setCardSizeAction = createAction<{ cardSize: CardSize }>('SET_CARD_SIZE');

export const setKanbanGroupByAction = createAction<{ customFieldId: string }>('SET_KANBAN_GROUP_BY');

/** Removes a custom field from configurable views, current configurable view and saved configurable views */
export const removeFieldFromConfigurableCurrentAndSavedView = createAction<{ fieldId: string }>(
  'REMOVE_FIELD_FROM_VIEWS',
);

export const setKanbanVisibleColumnsAction = createAction<{ customFieldValues: CustomFieldValue[] }>(
  'SET_KANBAN_VISIBLE_COLUMNS',
);

export const reorderKanbanColumnsByIdAction = createAction<{ activeColumnId: string; overColumnId: string }>(
  'REORDER_KANBAN_COLUMNS_BY_ID',
);

export const hideKanbanColumnAction = createAction<{ kanbanColumnId: string }>('HIDE_KANBAN_COLUMN');
export const showKanbanColumnAction = createAction<{ column: VisibleColumn }>('SHOW_KANBAN_COLUMN');
