import { createSelector } from 'reselect';

import { type PermissionsScopes, type PermissionsState } from './types';

export const permissionsStateSelector = ({ permissions }: { permissions: PermissionsState }) => permissions;

export const permissionsScopeSelector = createSelector(
  [permissionsStateSelector, (_state: { permissions: PermissionState }, scope: PermissionsScopes) => scope],
  (state, scope: PermissionsScopes) => state[scope],
);

export const permissionsSelector = createSelector(
  (state: { permissions: PermissionsState }, _scope: PermissionsScopes, _objectId: string) => state,
  (_state: { permissions: PermissionsState }, scope: PermissionsScopes, _objectId: string) => scope,
  (_state: { permissions: PermissionsState }, _scope: PermissionsScopes, objectId: string) => objectId,
  (state, scope, objectId) => state.permissions[scope][objectId],
);
