import { createSelector } from 'reselect';

import {
  getAssetRestorationTasksFromTasks,
  getBoardDeletionTasksFromTasks,
  getBoardRestorationTasksFromTasks,
  getCTWTasksFromTasks,
  getDuplicateBoardTasksFromTasks,
  getLibraryDeletionTasksFromTasks,
  getLibraryRestorationTasksFromTasks,
  getZippingTasksFromTasks,
} from '~/store/tasks/utils';
import { AirState } from '~/store/types';

const baseTasksSelector = ({ tasks }: AirState) => tasks;

export const tasksSelector = createSelector(baseTasksSelector, ({ tasks }) => tasks);

export const tasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(baseTasksSelector, ({ tasks }) =>
    tasks.filter((task) => !task.workspaceId || task.workspaceId === workspaceId),
  );

export const tasksSelectorByShortId = (shortId?: string) =>
  createSelector(baseTasksSelector, ({ tasks }) => tasks.filter((task) => !task.shortId || task.shortId === shortId));

export const zippingTasksSelector = createSelector(tasksSelector, (tasks) => getZippingTasksFromTasks(tasks));

export const makeZippingTasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getZippingTasksFromTasks(tasks));

export const makeZippingTasksSelectorByShortId = (shortId?: string) =>
  createSelector(tasksSelectorByShortId(shortId), (tasks) => getZippingTasksFromTasks(tasks));

export const makeCopyToWorkspaceTasksSelectorByShortId = (shortId?: string) =>
  createSelector(tasksSelectorByShortId(shortId), (tasks) => getCTWTasksFromTasks(tasks));

export const makeDuplicateBoardTasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getDuplicateBoardTasksFromTasks(tasks));

export const makeBoardDeletionTasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getBoardDeletionTasksFromTasks(tasks));

export const makeLibraryDeletionTasksSelectorByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getLibraryDeletionTasksFromTasks(tasks));

export const makeRestoreBoardTasksByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getBoardRestorationTasksFromTasks(tasks));

export const makeRestoreLibraryTasksByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getLibraryRestorationTasksFromTasks(tasks));

export const makeRestoreAssetTasksByWorkspaceId = (workspaceId?: string) =>
  createSelector(tasksSelectorByWorkspaceId(workspaceId), (tasks) => getAssetRestorationTasksFromTasks(tasks));

export const boardIdsBeingDeletedSelector = createSelector(tasksSelector, (tasks) =>
  getBoardDeletionTasksFromTasks(tasks).flatMap((task) =>
    task.status === 'in-progress' ? task.metadata.boardIds : [],
  ),
);

export const libraryIdsBeingDeletedSelector = createSelector(tasksSelector, (tasks) =>
  getLibraryDeletionTasksFromTasks(tasks).flatMap((task) =>
    task.status === 'in-progress' ? task.metadata.libraryId : [],
  ),
);

export const boardIdsBeingRestoredSelector = createSelector(tasksSelector, (tasks) =>
  getBoardRestorationTasksFromTasks(tasks).flatMap((task) =>
    task.status === 'in-progress' ? task.metadata.boardId : [],
  ),
);

export const libraryIdsBeingRestoredSelector = createSelector(tasksSelector, (tasks) =>
  getLibraryRestorationTasksFromTasks(tasks).flatMap((task) =>
    task.status === 'in-progress' ? task.metadata.libraryId : [],
  ),
);

export const assetIdsBeingRestoredSelector = createSelector(tasksSelector, (tasks) =>
  getAssetRestorationTasksFromTasks(tasks).flatMap((task) =>
    task.status === 'in-progress' ? task.metadata.assets.map(({ id }) => id) : [],
  ),
);
