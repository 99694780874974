import { AirActionTrackingLocation } from '@air/analytics';
import {
  BilateralCornerArrows,
  BoardAdd,
  BoardDuplicate,
  BoxLogo,
  BrandfolderLogo,
  DesktopApp,
  Document,
  Download,
  Dropbox,
  Edit,
  Fields,
  Finder,
  Gear,
  GoogleDrive,
  Images,
  Import,
  ImportFolder,
  Info,
  Link,
  LogOut,
  Megaphone,
  Merge,
  Move,
  Remove,
  SalesAgent,
  SharePointIcon,
  Slack,
  Star,
  StarFilled,
  Support,
  Tag,
  Trash,
  Uploads,
  Video,
} from '@air/next-icons';
import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { ReactNode } from 'react';

import { CUSTOM_FIELDS_CONTEXT_MENU_EDIT } from '~/constants/testIDs';
import { IconType } from '~/types/icons';

export type GetItemMenuOptionsCallback<T> = (params: { item: T }) => GetItemMenuOptions;

export type GetItemMenuOptions = (params: { trackLocation: AirActionTrackingLocation }) => DropdownMenuOption[];

export type ImportOptions =
  | 'import-from-drive'
  | 'import-from-box'
  | 'import-from-dropbox'
  | 'import-from-brandfolder'
  | 'import-from-sharePoint';

export type MenuOptionsName =
  | ImportOptions
  | 'account-settings'
  | 'add-sub-board'
  | 'add-to-board'
  | 'chat-with-support'
  | 'connect-to-slack'
  | 'connected-to-slack'
  | 'content-collection'
  | 'copy-to-air'
  | 'create-custom-field'
  | 'create-new-board'
  | 'download-adobe-extension'
  | 'download-air-flow'
  | 'edit-custom-fields'
  | 'edit-tags'
  | 'export-as'
  | 'delete'
  | 'delete-version'
  | 'delete-tag'
  | 'download'
  | 'download-proxy'
  | 'duplicate'
  | 'edit'
  | 'edit-inline'
  | 'favorite'
  | 'guests-can-comment'
  | 'guests-can-edit'
  | 'guests-can-view'
  | 'guests-remove-self'
  | 'guests-remove'
  | 'help-center'
  | 'import'
  | 'leave-feedback'
  | 'log-out'
  | 'meet-with-sales'
  | 'merge'
  | 'move'
  | 'notifications-settings'
  | 'open-asset'
  | 'remove-from-board'
  | 'remove-from-version-stack'
  | 'rename'
  | 'rename-inline'
  | 'rename-tag'
  | 'save-to-air'
  | 'set-board-thumbnail'
  | 'share'
  | 'unfavorite'
  | 'upload'
  | 'upload-files'
  | 'upload-folders'
  | 'view-all-tagged'
  | 'white-delete'
  | 'dark-delete'
  | 'open-on-desktop';

export type MenuOptionValue = {
  id: string;
  Icon?: IconType;
  iconColor?: string;
  label: ReactNode;
  labelColor?: string;
  description?: string;
  'data-testid'?: string;
};

/**
 * Source of truth for all actions available in a menu, across Clip, Board, Login, etc in any combination.
 */
export const MenuOptions: Record<MenuOptionsName, MenuOptionValue> = {
  'account-settings': {
    id: 'account-settings',
    Icon: Gear,
    label: 'Account Settings',
  },
  'edit-tags': {
    id: 'edit-tags',
    Icon: Tag,
    label: 'Edit tags',
  },
  'add-to-board': {
    id: 'add-to-board',
    Icon: BoardAdd,
    label: 'Add to board',
  },
  'add-sub-board': {
    id: 'add-sub-board',
    Icon: BoardAdd,
    label: 'Add Sub-board',
  },
  'chat-with-support': {
    id: 'chat-with-support',
    Icon: Support,
    label: 'Chat with support',
  },
  'connect-to-slack': {
    id: 'connect-to-slack',
    Icon: Slack,
    label: 'Connect to Slack',
  },
  'connected-to-slack': {
    id: 'connected-to-slack',
    Icon: Slack,
    label: 'Connected to Slack',
  },
  'download-adobe-extension': {
    id: 'download-air-for-adobe-premiere-pro',
    Icon: Video,
    label: 'Download Air for Premiere Pro',
  },
  'download-air-flow': {
    id: 'download-air-flow',
    Icon: DesktopApp,
    label: 'Download Air Flow',
  },
  'edit-custom-fields': {
    id: 'edit-custom-fields',
    'data-testid': CUSTOM_FIELDS_CONTEXT_MENU_EDIT,
    Icon: Fields,
    label: 'Edit custom fields',
  },
  'create-custom-field': { id: 'create-custom-field', label: '+ Create new custom field' },
  'create-new-board': {
    id: 'create-new-board',
    Icon: BoardAdd,
    label: 'Create new board',
  },
  delete: {
    id: 'delete',
    Icon: Trash,
    iconColor: 'flamingo500',
    label: 'Delete from workspace',
    labelColor: 'flamingo800',
  },
  'dark-delete': {
    id: 'dark-delete',
    Icon: Trash,
    iconColor: 'flamingo300',
    label: 'Delete from workspace',
    labelColor: 'flamingo300',
  },
  'white-delete': {
    id: 'white-delete',
    Icon: Trash,
    label: 'Delete from workspace',
    'data-testid': 'white-delete',
  },
  ['delete-version']: {
    id: 'delete-version',
    label: 'Delete version',
    labelColor: 'flamingo800',
  },
  ['delete-tag']: {
    id: 'delete-tag',
    label: 'Delete tag',
    labelColor: 'flamingo800',
    'data-testid': 'delete-tag',
  },
  download: {
    id: 'download',
    Icon: Download,
    label: 'Download',
  },
  'download-proxy': {
    id: 'download-proxy',
    label: 'Download proxy',
    Icon: Download,
  },
  duplicate: {
    id: 'duplicate',
    Icon: BoardDuplicate,
    label: 'Duplicate board',
  },
  edit: {
    id: 'edit',
    Icon: Edit,
    label: 'Edit details',
  },
  'edit-inline': {
    id: 'edit-inline',
    Icon: Edit,
    label: 'Rename',
  },
  'export-as': {
    id: 'export-as',
    Icon: Download,
    label: 'Export as',
  },
  favorite: {
    id: 'favorite',
    Icon: Star,
    label: 'Favorite',
  },
  'help-center': {
    id: 'help-center',
    Icon: Info,
    label: 'Help center',
  },
  import: {
    id: 'import',
    Icon: Import,
    label: 'Import from...',
  },
  'import-from-drive': {
    id: 'import-from-drive',
    Icon: GoogleDrive,
    label: 'Google Drive',
  },
  'import-from-dropbox': {
    id: 'import-from-dropbox',
    Icon: Dropbox,
    iconColor: '#0061FF',
    label: 'Dropbox',
  },
  'import-from-box': {
    id: 'import-from-box',
    Icon: BoxLogo,
    iconColor: '#0061D5',
    label: 'Box',
  },
  'import-from-brandfolder': {
    id: 'import-from-brandfolder',
    Icon: BrandfolderLogo,
    label: 'Brandfolder',
  },
  'import-from-sharePoint': {
    id: 'import-from-sharePoint',
    Icon: SharePointIcon,
    iconColor: '#0061D5',
    label: 'SharePoint',
  },
  'log-out': {
    id: 'log-out',
    Icon: LogOut,
    label: 'Log out',
  },
  'guests-can-comment': {
    id: 'guests-can-comment',
    label: 'Can Comment',
    description: 'Can view and comment, but cannot add, edit, or delete any content',
  },
  'guests-can-edit': {
    id: 'guests-can-edit',
    label: 'Can Edit',
    description: 'Can edit all content on this board, but cannot delete the board itself',
  },
  'guests-can-view': {
    id: 'guests-can-view',
    label: 'Can View',
    description: 'Can view all content, but cannot view or contribute to discussions',
  },
  'guests-remove-self': {
    id: 'guests-remove-self',
    label: 'Leave this Board',
  },
  'guests-remove': {
    id: 'guests-remove',
    label: 'Remove from board',
  },
  'leave-feedback': {
    id: 'leave-feedback',
    Icon: Megaphone,
    label: 'Leave feedback',
  },
  'meet-with-sales': {
    id: 'meet-with-sales',
    Icon: SalesAgent,
    label: 'Meet with sales',
  },
  move: {
    id: 'move',
    Icon: Move,
    label: 'Move to…',
  },
  'notifications-settings': {
    id: 'notifications-settings',
    label: 'Notifications settings',
  },
  'open-asset': {
    id: 'open-asset',
    Icon: BilateralCornerArrows,
    label: 'Open',
  },
  'remove-from-board': {
    id: 'remove-from-board',
    Icon: Remove,
    label: 'Remove from this board',
  },
  'remove-from-version-stack': {
    id: 'remove-from-version-stack',
    label: 'Remove from version stack',
  },
  rename: {
    id: 'rename',
    Icon: Edit,
    label: 'Rename',
  },
  'rename-inline': {
    id: 'rename-inline',
    Icon: Edit,
    label: 'Rename',
  },
  ['rename-tag']: {
    id: 'rename-tag',
    label: 'Rename',
  },
  'content-collection': {
    id: 'content-collection',
    Icon: Document,
    label: 'Collect content',
  },
  'save-to-air': {
    id: 'save-to-air',
    Icon: Move,
    label: 'Save to Air workspace',
  },
  'set-board-thumbnail': {
    id: 'set-board-thumbnail',
    Icon: Images,
    label: 'Set as board thumbnail',
    'data-testid': 'set-board-thumbnail',
  },
  share: {
    id: 'share',
    Icon: Link,
    label: 'Share a link',
  },
  upload: {
    id: 'upload',
    Icon: Uploads,
    label: 'Upload',
  },
  unfavorite: {
    id: 'unfavorite',
    Icon: StarFilled,
    label: 'Unfavorite',
  },
  'copy-to-air': {
    id: 'copy-to-air',
    Icon: Move,
    label: 'Copy to Air workspace',
  },
  'upload-files': {
    id: 'upload-files',
    Icon: Import,
    label: 'File Upload',
  },
  'upload-folders': {
    id: 'upload-folders',
    Icon: ImportFolder,
    label: 'Folder Upload',
  },
  'view-all-tagged': {
    id: 'view-all-tagged',
    label: 'View all tagged',
  },
  'open-on-desktop': {
    id: 'open-on-desktop',
    label: 'Open in Finder',
    Icon: Finder,
  },
  merge: { id: 'merge', Icon: Merge, label: 'Merge', 'data-testid': 'merge' },
};

export const getMenuOption = (key: string | undefined): MenuOptionValue | null => {
  if (!key) return null;
  return MenuOptions[key as MenuOptionsName] ?? null;
};
