import { type Clip, ClipType } from '@air/api/types';

import { isPdfRenderedExtension } from './isPdfRenderedExtension';

/**
 * Pass clip type and ext to check if clip can be annotated.
 * Clips that can not be annotated:
 * - audio
 * - nonMedia, unless it is {@link isPdfRenderedExtension}
 */
export const canClipBeAnnotated = ({ type, ext }: Pick<Clip, 'ext' | 'type'>) => {
  if (isPdfRenderedExtension(ext)) {
    return true;
  } else if (type === ClipType.nonMedia || type === ClipType.audio) {
    return false;
  }
  return true;
};
