import { ClipStatus, ClipType } from '@air/api/types';
import { getVideoFormatFromUrl } from '@air/utils-video';

export type ClipStatusTypes = keyof typeof ClipStatus;

const TERMINAL_STATUSES = [ClipStatus.transcoded, ClipStatus.nonTranscodable];

export const Rotations = {
  landscape: 0,
  otherLandscape: 180, // Transcoder uses -180
  otherOtherLandscape: -180,
  portrait: -90,
  upsideDownPortrait: 90,
};

class ClipUtils {
  static isTranscoded = (status?: ClipStatusTypes | null) => status === ClipStatus.transcoded;
  static isProcessing = (status?: ClipStatusTypes | null) =>
    status === ClipStatus.transcoding || status === ClipStatus.uploaded || status === ClipStatus.created;
  static isFailed = (status?: ClipStatusTypes | null) => status === ClipStatus.failed;
  static isNonTranscodable = (status?: ClipStatusTypes | null) => status === ClipStatus.nonTranscodable;
  static isTranscodeFinished = (status?: ClipStatus | null) =>
    (status && TERMINAL_STATUSES.includes(status)) || status === ClipStatus.failed;

  static canHavePreviewImage = (clipType: ClipType) => clipType !== ClipType.nonMedia && clipType !== ClipType.audio;

  static determinePlaybackRate = (duration: number, videoSrc: string) => {
    const fmt = getVideoFormatFromUrl(videoSrc);
    if (fmt !== 'm3u8') return 1;

    if (duration < 15) return 2;
    if (duration < 30) return 3;
    if (duration < 60) return 4;
    return 5;
  };

  static isLandscape = (rotation: number) => {
    if (
      rotation === Rotations.landscape ||
      rotation === Rotations.otherLandscape ||
      rotation === Rotations.otherOtherLandscape
    ) {
      return true;
    }

    if (!(rotation === Rotations.portrait || rotation === Rotations.upsideDownPortrait)) {
      // console.warn('unexpected rotation metadata', rotation);
    }
    return false;
  };

  static changeVideoTimeInSeconds = (video: HTMLVideoElement, seconds: number) => {
    let newTime = video.currentTime + seconds;
    if (newTime > video.duration) newTime = video.duration;
    if (newTime < 0) newTime = 0;
    video.currentTime = newTime;
  };

  static changeVideoVolume = (video: HTMLVideoElement, amount: number) => {
    let newVolume = video.volume + amount;
    if (newVolume > 1) newVolume = 1;
    if (newVolume < 0) newVolume = 0;
    video.volume = newVolume;
  };
}

export { ClipUtils };
