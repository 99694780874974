import { resetUploadsAction } from '@air/redux-uploader';
import { createReducer } from '@reduxjs/toolkit';

import {
  addPathToBoardIdAction,
  setCreatingUploadBoardsAction,
  setCreatingUploadBoardsFailedAction,
} from '~/store/uploadingPathToBoardId/actions';
import { UploadingPathToBoardIdState } from '~/store/uploadingPathToBoardId/types';

const initialState: UploadingPathToBoardIdState = {};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetUploadsAction, () => ({}))

    .addCase(setCreatingUploadBoardsAction, (state, action) => {
      const { paths, pathRootId } = action.payload;
      /**
       * To prevent overwriting existing paths that are being created or have a boardId,
       * we filter out any paths that either:
       * 1. Don't exist in state yet, or
       * 2. Exist but are not currently being created and don't have a boardId
       */
      const newPaths = paths.filter((path) => {
        const pathToBoard = state[pathRootId]?.[path];
        return !pathToBoard || (!pathToBoard.isCreating && !pathToBoard.boardId);
      });

      newPaths.forEach((path) => {
        const currentPathToBoard = state[pathRootId] || {};

        state[pathRootId] = {
          ...currentPathToBoard,
          [path]: {
            ...(currentPathToBoard[path] || {}),
            isCreating: true,
            error: undefined,
          },
        };
      });
    })
    .addCase(setCreatingUploadBoardsFailedAction, (state, action) => {
      const { paths, pathRootId, error } = action.payload;

      // Filter out any paths that are already created
      const newPaths = paths.filter((path) => !state[pathRootId]?.[path]?.boardId);

      newPaths.forEach((path) => {
        const currentPathToBoard = state[pathRootId] || {};

        state[pathRootId] = {
          ...currentPathToBoard,
          [path]: {
            ...(currentPathToBoard[path] || {}),
            isCreating: false,
            error: error as Error,
          },
        };
      });
    })

    .addCase(addPathToBoardIdAction, (state, action) => {
      const { path, boardId, pathRootId } = action.payload;
      const currentPathToBoard = state[pathRootId] || {};
      state[pathRootId] = {
        ...currentPathToBoard,
        [path]: {
          ...(currentPathToBoard[path] || {}),
          isCreating: false,
          boardId,
        },
      };
    });
});
