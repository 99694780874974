import { type Clip, ClipStatus } from '@air/api/types';

import { isPdfRenderedExtension } from './isPdfRenderedExtension';

/**
 * a parameter that is ignored by imgix but used by the frontend
 * to clear the browser's cached thumbnails
 */
export const clearThumbnailCache = ({
  clipStatus,
  clipExt,
  clipUpdatedAt,
  imageSrc,
}: {
  clipStatus: Clip['status'];
  clipExt: Clip['ext'];
  clipUpdatedAt: Clip['updatedAt'];
  imageSrc: Clip['assets']['image'];
}) => {
  if (isPdfRenderedExtension(clipExt) && clipStatus === ClipStatus.transcoded && imageSrc) {
    return `${imageSrc}?updatedAt=${new Date(clipUpdatedAt).getTime()}`;
  }
  return imageSrc;
};
