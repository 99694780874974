import { useTrackAppliedSearchTerm, useTrackRemovedSearchTerm } from "@air/analytics";
import Router from "next/router";
import { useCallback } from "react";
import { QueryParamNames } from "~/constants/search";
import { useFiltersContext } from "~/providers/FiltersProvider";
import { getPathWithOmittedParams, getPathWithoutQuery, pushWithExistingQuery } from "~/utils/PathUtils";
export const useSearchParams = ()=>{
    const { search } = useFiltersContext();
    const { trackAppliedSearchTerm } = useTrackAppliedSearchTerm();
    const { trackRemovedSearchTerm } = useTrackRemovedSearchTerm();
    const changeQueryParams = useCallback((term)=>{
        if (term === search) {
            return;
        }
        trackAppliedSearchTerm({
            term
        });
        return pushWithExistingQuery({
            path: getPathWithoutQuery(Router.asPath),
            newQuery: {
                [QueryParamNames.Search]: term
            }
        });
    }, [
        search,
        trackAppliedSearchTerm
    ]);
    const clearSearch = useCallback(()=>{
        trackRemovedSearchTerm();
        return Router.push(getPathWithOmittedParams(Router.asPath, [
            QueryParamNames.Search
        ]), undefined, {
            shallow: true
        });
    }, [
        trackRemovedSearchTerm
    ]);
    return {
        search,
        clearSearch,
        setSearch: changeQueryParams,
        hasSearchParams: !!search
    };
};
