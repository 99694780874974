import { type PublicClip } from '@air/api';
import { type AssetCustomFieldValue, type BoardCustomFieldValue, type ShortUrlForm } from '@air/api/types';

export type ContentCollectionCustomField = Omit<AssetCustomFieldValue & BoardCustomFieldValue, 'assetId' | 'boardId'>;

export enum ContentCollectionView {
  Onboarding = 'onboarding',
  Manage = 'manage',
  Completed = 'completed',
}

export type ContentCollectionState = {
  applyAll: boolean;
  customFields: ContentCollectionCustomField[];
  form: ShortUrlForm | undefined;
  shortId: string | undefined;
  touched: boolean;
  uploadedClips: PublicClip[];
  view: ContentCollectionView;
};
