import { useFlags } from 'launchdarkly-react-client-sdk';

export type LaunchDarklyFlags = {
  // TODO: add more launchdarkly flags that we read from useFlags
  contentCollection: boolean;
  deletionRecovery: boolean;
  libraries: boolean;
  hideShortUrlSignUpButton: string[];
  showUnnamedPeople: boolean;
  whiteLabelWorkspaces?: string[];
  enableSaml: boolean;
};

export const useLaunchDarklyFlags = () => useFlags<LaunchDarklyFlags>();
