import { type Clip } from '@air/api/types';

import { getAssetIsFailed } from './getAssetIsFailed';
import { getAssetIsNonTranscodable } from './getAssetIsNonTranscodable';
import { getAssetIsTranscoded } from './getAssetIsTranscoded';

export const getAssetIsProcessing = (asset?: { status?: Clip['status'] | null }) =>
  [getAssetIsFailed, getAssetIsTranscoded, getAssetIsNonTranscodable].reduce((isProcessing, check) => {
    return isProcessing && !check(asset);
  }, true);
