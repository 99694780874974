import { Board, UploadUrlResponse } from '@air/api/types';
import { isAirror, MAX_ANCESTOR_COUNT_EXCEEDED } from '@air/errors';
import {
  setSmallUploadCancellablesAction,
  setSmallUploadProgressAction,
  setUploadCompletedAction,
  setUploadFailedAction,
  setUploadPreparingAction,
  setUploadUploadingAction,
  smallUploadByIdSelector,
  Upload,
  UploadStatus,
  useUploaderStore,
} from '@air/redux-uploader';
import { uploadFile } from '@air/upload';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UploadFileBaseParams } from '../types';
import { isAbortError } from '../utilities';

export interface UploadSmallParams {
  uploadId: Upload['id'];
  parentBoardId?: Board['id'];
  onUploadStarted: UploadFileBaseParams['onUploadStarted'];
  onUploadFinished: (params: { uploadId: Upload['id'] }) => Promise<void> | void;
  getUploadUrls: (upload: Upload) => Promise<UploadUrlResponse[0] | undefined>;
  onError: (params: { upload: Upload; error: unknown }) => void;
}

export const useUploadSmall = () => {
  const store = useUploaderStore();
  const dispatch = useDispatch();

  const uploadSmall = useCallback(
    async ({ uploadId, getUploadUrls, onUploadStarted, onUploadFinished, onError }: UploadSmallParams) => {
      const upload = smallUploadByIdSelector(store.getState(), uploadId);

      if (upload && upload.status === UploadStatus.queued) {
        dispatch(setUploadPreparingAction({ uploadId: upload.id }));
        try {
          const uploadUrlInfo = upload.s3Info.uploadUrlInfo ?? (await getUploadUrls(upload));

          dispatch(setUploadUploadingAction({ uploadId: upload.id }));
          if (uploadUrlInfo) {
            const onProgress: UploadFileBaseParams['onProgress'] = ({ progress, abort }) => {
              if (progress) {
                dispatch(setSmallUploadProgressAction({ uploadId: upload.id, progress, abort }));
              }
            };

            const onUploadStart = (xhr: XMLHttpRequest) => {
              dispatch(setSmallUploadCancellablesAction({ xhr, uploadId: upload.id, uploadUrlInfo: uploadUrlInfo }));
              onUploadStarted({ uploadId: upload.id });
            };

            const { url, mime } = uploadUrlInfo;

            await uploadFile({ file: upload.file, url, contentType: mime, onProgress, onStart: onUploadStart });

            await onUploadFinished({ uploadId: upload.id });
            dispatch(setUploadCompletedAction({ uploadId: upload.id }));
          } else {
            // TODO: handle error
          }
        } catch (error) {
          if (!isAbortError(error)) {
            dispatch(
              setUploadFailedAction({
                uploadId: upload.id,
                errorMessage:
                  isAirror(error) && error.type === MAX_ANCESTOR_COUNT_EXCEEDED.type
                    ? 'Max ancestor count exceeded'
                    : 'Failed',
              }),
            );
          }
          onError({ upload, error });
        }
      }
    },
    [dispatch, store],
  );

  return {
    uploadSmall,
  };
};
